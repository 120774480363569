export interface PriceList {
    cut: string;
    price: string;
}

export const services = [
    {
        service_name: "extensions",
        services: [
            
            {
                cut: 'Initial Install (new hair)',
                price: '$300/1 row'
            },
            {
                cut: '',
                price: '$500/2 rows'
            },
            {
                cut: '',
                price: '$700/3 rows'
            },
            {
                cut: 'Reinstall',
                price: '$150/row'
            },
            {
                cut: '',
                price: '$450/3 rows',
            }
        ]
    },
    {
        service_name: "color",
        services: [
            {
                cut: 'Express Custom Color Refresh',
                price: '$125'
            },
            {
                cut: 'Custom Color Refresh',
                price: '$200'
            },
            {
                cut: 'Partial Custom Color',
                price: '$250'
            },
            {
                cut: 'Full Custom Color',
                price: '$300'
            },
            {
                cut: 'Luxe Custom Color',
                price: '$400'
            }
        ]
    },
    {
        service_name: "style",
        services: [
            {
                cut: 'Express/Clipper Cut',
                price: '$60'
            },
            {
                cut: 'Haircut and Style' /*\n*includes integrity insurance treatment'*/,
                price: '$75'
            },
            {
                cut: 'Luxe Haircut and Style',
                price: '$110'
            },
            {
                cut: 'Styling Lesson',
                price: '$100'
            },
            {
                cut: 'Brazilian Blowout',
                price: '$200'
            }
        ]
    },
]

export const extensions: PriceList[] = [
    {
        cut: 'Initial Install (new hair)',
        price: '$300/1 row'
    },
    {
       cut: '',
        price: '$500/2 rows'
    },
    {
        cut: '',
        price: '$750/3 rows'
    },
    {
        cut: '',
        price: '$1000/4 rows'
    },
    {
        cut: 'Reinstall',
        price: '$200/1 row'
    },
    {
        cut: '',
        price: '$300/2 rows'
    },
    {
        cut: '',
        price: '$450/3 rows'
    },
    {
        cut: '',
        price: '$600/4 rows'
    }
]
export const color: PriceList[] = [
    {
        cut: 'Express Custom Color Refresh',
        price: '$125'
    },
    {
        cut: 'Custom Color Refresh',
        price: '$200'
    },
    {
        cut: 'Partial Custom Color',
        price: '$250'
    },
    {
        cut: 'Full Custom Color',
        price: '$300'
    },
    {
        cut: 'Luxe Custom Color',
        price: '$400'
    }
]

export const style: PriceList[] = [
    {
        cut: 'Express/Clipper Cut',
        price: '$60'
    },
    {
        cut: 'Haircut and Style' /*\n*includes integrity insurance treatment'*/,
        price: '$75'
    },
    {
        cut: 'Luxe Haircut and Style',
        price: '$110'
    },
    {
        cut: 'Styling Lesson',
        price: '$100'
    },
    {
        cut: 'Brazilian Blowout',
        price: '$200'
    }
]


