import React from "react";
import './GetToKnowPage.css';
import { useLocation } from "react-router-dom";
import { questionnaire } from "../data/questionaire.data";
import ImageGallery from '../components/ImageGallery';

export const GetToKnowPage: React.FC = () => {
    return (
        <React.Fragment>
            <h4>Your dream hair, untangled!</h4>
            <div className="imgContainer">
                <img loading="lazy" src="assets/images/extensions.jpg" alt="" />
            </div>
            <div className="row">
                <div className="imgContainer2">
                    <img loading="lazy" src="assets/images/book.jpg" alt="" />
                </div>
                <div className="imgContainer2">
                    <img loading="lazy" src="assets/images/silly.jpg" alt="" />
                </div>
            </div>
            <h1>Behind the Chair</h1>
                {/* <p>I specialize in ammonia free hair color, and giving you the hair of your dreams through Invisible Bead Extensions®.</p> */} 
                <p>I’m Darlene, but I’ll answer to any of the following. D, Queen D, D Money, Sassy Pants, Cajun Spice, and Darlene. Since 2011, I’ve been making people feel like a million bucks, all the way from my hometown Chicago.</p>
                <p>I love creating beautiful hair, but my true passion lies in giving my guests meaningful experiences. That’s what inspired me to open Studio D Luxe. Building genuine connections with each guest is where my heart truly lies. I specialize in precision haircuts, integrity treatments, and effortless hair care, ensuring you leave my chair feeling your best and ready to shine until your next visit.</p>
                <p>What I love most about being your hairstylist is making sure your hair is easy to manage every day. It’s not just about how you look on the day of your appointment—it’s about equipping you with the knowledge to keep your hair healthy, so you can feel confident and look great all the time!</p>
            <div className="row">
            <div className="gettoknowimage">
                <img loading="lazy" src="assets/images/DandMatt2.jpeg" width="175" height="200" alt="" />
            </div>
            <div className="gettoknowimage2">
                    <img loading="lazy" src="assets/images/kitty2.jpeg" width="215" height="250" alt="" />
            </div>
            </div>
            <div className="row">
            <div className="gettoknowimage3">
                    <img loading="lazy" src="assets/images/kitty1.jpeg" width="250" height="250"alt="" />
                </div>
                <div className="gettoknowimage4">
                    <img loading="lazy" src="assets/images/DandMatt.jpeg" width="180" height="200"alt="" />
                </div>
                
            </div>
            
            <h1>A Little Bit <s>Alexis</s>"D"</h1>
                <p>Before I head into the salon each day, you can find me giving all the sass to the trainers in my workout class. After work, I’m either hanging out at my sisters, or enjoying time with my boyfriend Matt and our fur babies Lucy and Ethel. We are big time home bodies, yet always looking for our next date night spot.</p>
            
                <div className = 'gettoknow'>
                <ul><li><h3>Bliss</h3></li> 
                <li><p>Schitt's Creek. Obvi.</p></li>
                <li><p>Playing games with my family</p></li>
                <li><p>Trolls dance parties with my baby niece</p></li></ul>
                </div>
                <div className = 'gettoknow'>
                <ul><li><h3>Vice</h3></li>
                <li><p>Keeping up with the Kardashians</p></li>
                <li><p>Sutter Home Chardonnay</p></li>
                <li><p>Any kind of drama I can find in my real life.</p></li>
                </ul>
                </div>
                <div className = 'gettoknow'>
                <ul><li><h3>Couldn't Be Me</h3></li>
                <li><p>Going to bed at a decent hour</p></li>
                <li><p>Not being victimized by dairy</p></li>
                <li><p>Having a filter</p></li>
                </ul>
                </div>


                {/*{questionnaire.map((question, index) => {
                    return (
                        <div key={index} className="questionWrapper">
                            <div className="questionContent">{question.question}</div>
                            <p className="answerContent">{question.answer}</p>
                        </div>
                    )
                })}
                {/* <footer></footer> */}
        </React.Fragment>
    )
};

export default GetToKnowPage;